<template>
    <div class="page-container">
        <main>
            <loading-screen v-if="is_loading"></loading-screen>
            <v-calendar v-else class="custom-calendar max-w-full" :masks="masks"
                        :attributes="attributes" disable-page-swipe is-expanded>
                <template v-slot:day-content="{ day, attributes }">
                    <div class="day-container">
                        <span class="day-wrapper">{{ day.day }}</span> <span class="day-label">{{day.ariaLabel}}</span>
                        <div class="bookings-wrapper">
                            <p v-for="attr in attributes"
                               :key="attr.key"
                               class="booking-container"
                               :class="attr.customData.class"
                                @click="toggleUpdate(attr.customData)">
                                <span style="display: block;">{{ attr.customData.timeSlot }} | {{ attr.customData.referenceNumber }}</span>
                                <span style="display: block;">{{ attr.customData.organisation }} - {{ attr.customData.room }}</span>
                                <span style="display: block; text-transform: capitalize">Status: {{ attr.customData.status }}</span>
                            </p>
                        </div>
                    </div>
                </template>
            </v-calendar>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import LoadingScreen from "../../components/LoadingScreen";
import UsersCreateModal from "../../components/users/UsersCreateModal";
import UsersUpdateModal from "../../components/users/UsersUpdateModal";
import BookingsUpdateModal from "../../components/bookings/BookingsUpdateModal";

export default {
    name: "calendar-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar, LoadingScreen},
    data: function () {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        return {
            masks: {
                weekdays: 'WWW',
            },
            attributes: [],
            bookings: [],
            is_loading_users: false,
            is_loading: true,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        async toggleUpdate(booking) {
            this.$modal.show(
                BookingsUpdateModal,
                {
                    booking_id: booking.bookingId,
                },
                {
                    name: 'bookings-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': async (e) => {
                        if (e.params === true) {
                            await this.retrieveBookings();
                            await this.generateCalendarAttributes();
                        }
                    }
                }
            );
        },

        async retrieveBookings() {
            this.is_loading_users = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            await this.$axios.get('bookings/list', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.bookings = data.data;
                    this.is_loading_users = false;
                })
                .catch(e => {
                    this.is_loading_users = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async generateCalendarAttributes() {
            this.attributes = [];
            this.is_loading = true;

            this.bookings.forEach((booking) => {
                let fromTime = booking.attributes.from.split(" ")[1].substring(0, booking.attributes.from.split(" ")[1].length - 3)
                let toTime = booking.attributes.to.split(" ")[1].substring(0, booking.attributes.to.split(" ")[1].length - 3)

                let bookingDate = this.$moment(booking.attributes.from, 'YYYY-MM-DD HH:mm:ss')
                let dayTime = bookingDate.date();
                let monthTime = bookingDate.month();
                let yearTime = bookingDate.year();

                let customCSS = '';

                if (booking.attributes.status === 'pending')
                    customCSS = 'bg-pending text-white'
                if (booking.attributes.status === 'accepted')
                    customCSS = 'bg-accepted text-white'
                if (booking.attributes.status === 'rejected')
                    customCSS = 'bg-rejected text-white'
                if (booking.attributes.status === 'cancelled')
                    customCSS = 'hidden'

                this.attributes.push({
                    key: 0,
                    customData: {
                        bookingId: booking.id,
                        referenceNumber: booking.attributes.reference,
                        timeSlot: `${fromTime} - ${toTime}`,
                        organisation: booking.relationships.organisation.data.attributes.organisation,
                        room: booking.relationships.room.data.attributes.name,
                        class: customCSS,
                        status: booking.attributes.status,
                    },
                    dates: new Date(yearTime, monthTime, dayTime, Number(fromTime.substring(0, 2))),
                })

            })

            this.attributes.sort(function (a, b) {
                return new Date(a.dates) - new Date(b.dates);
            });

            this.attributes.forEach((element, index) => {
                element.key = index
            })
            this.is_loading = false;
        },
    },

    async mounted() {
        await this.retrieveBookings();
        await this.generateCalendarAttributes();
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.calendar')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        @apply bg-grey-light rounded-lg;
    }

    ::-webkit-scrollbar-thumb {
        @apply bg-grey-darker rounded-lg;
    }

    ::-webkit-scrollbar-thumb:hover {
        @apply bg-grey-dark;
    }

    .day-container {
        @apply flex flex-col h-full z-10 overflow-hidden;

        .day-label {
            @screen sm {
                @apply hidden;
            }
        }

        .day-wrapper {
            @apply hidden;

            @screen sm {
                @apply block;
            }
        }

        .bookings-wrapper {
            @apply flex-grow overflow-y-auto overflow-x-auto pr-1;

            .booking-container {
                @apply text-xs leading-tight rounded-md p-1 mt-2 mb-1 cursor-pointer;
            }
        }

    }
}
</style>
