<template>
    <div class="page-container" v-if="!is_loading">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{ original.attributes.name }}</h1>
            </template>
            <template v-slot:right>
                <Button className="--primary --small --wider"
                        :class="{spinner: is_saving}" :onclick="save"
                        v-if="$store.getters.hasAnyPermission(['update rooms'])">
                    {{ $t('save') }}
                </Button>
                <Button className="--primary --small --wider"
                        :class="{spinner: is_saving}" :onclick="save"
                        v-if="$store.getters.hasAnyPermission(['read rooms'])">
                    {{ $t('bookings.book') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <FormGroupTwo class="section-container-wrapper">
                <div class="section-container half">
                    <Form class="form" :disabled="!$store.getters.hasPermission('update rooms') || is_saving">
                        <SectionHeader :title="$t('rooms.room_details')"></SectionHeader>
                        <div class="form-body">
                            <FormGroupThree>
                                <FormInputText v-model="$v.room.name.$model" identifier="name" :label="$t('rooms.name')"
                                               :placeholder="$t('rooms.name')" :disabled="is_saving"
                                               :has-error="$v.room.name.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.room.name.required">
                                            {{ $t('validation.x_is_required', {x: $t('rooms.name')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                                <FormInputText v-model="$v.room.capacity.$model" identifier="capacity"
                                               :label="$t('rooms.capacity')"
                                               :placeholder="$t('rooms.capacity')" :disabled="is_saving"
                                               :has-error="$v.room.capacity.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.room.capacity.required">
                                            {{ $t('validation.x_is_required', {x: $t('rooms.capacity')}) }}
                                        </p>
                                    </template>
                                </FormInputText>

                                <FormInputText v-model="$v.room.size.$model" identifier="size" :label="$t('rooms.size')"
                                               :placeholder="$t('rooms.size')" :disabled="is_saving"
                                               :has-error="$v.room.size.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.room.size.required">
                                            {{ $t('validation.x_is_required', {x: $t('rooms.size')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                            </FormGroupThree>
                            <div class="image-section">
                                <div v-if="roomImages.length" class="images">
                                    <div class="images-container">
                                        <div v-for="(image, index) in roomImages" class="image-wrapper">
                                            <div class="image-container">
                                                <button type="button" class="btn-delete"
                                                        @click="removeAttachment(index)">
                                                    <font-awesome-icon :icon="['far','times']"/>
                                                </button>
                                                <img :src="image.preview"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="upload-row">
                                        <label for="property-create-file-upload">
                                            <font-awesome-icon :icon="['fal','upload']"/>
                                            <p>{{ $t('rooms.upload_images') }}</p>
                                        </label>
                                    </div>
                                </div>
                                <div v-else class="upload">
                                    <label for="property-create-file-upload">
                                        <font-awesome-icon :icon="['fal','upload']"/>
                                        <p>{{ $t('rooms.upload_images') }}</p>
                                    </label>
                                </div>

                                <input type="file" name="file" id="property-create-file-upload" accept="image/*"
                                       multiple @change="handleFileChange" :disabled="is_saving"/>
                            </div>
                        </div>
                    </Form>
                </div>
                <div class="section-container half">
                    <vue-good-table
                        mode="remote"
                        styleClass="vgt-table vgt-custom vgt-custom-update-room"
                        :columns="amenitiesColumns"
                        :rows="amenities"
                        :isLoading.sync="is_loading_amenities"
                        :search-options="{
                    enabled: false,
                }"
                        :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('amenities.amenities')}),
                }"
                        :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                        :totalRows="amenities_records"
                        @on-page-change="onPageChangeAmenities"
                        @on-sort-change="onSortChange">
                        <template slot="table-column" slot-scope="props">
                            <div class='add-amenity-button-container' v-if="props.column.field =='after'">
                                <Button
                                    class="add-amenity-button"
                                    className="--primary --small --wider"
                                    :class="{spinner: is_saving}" :onclick="toggleAddAmenity">
                                    <span>{{ $t('add') }}</span>
                                </Button>
                            </div>
                        </template>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field === 'after'" class="td-after">
                                <Button
                                    v-tippy="{ placement : 'top-middle', arrow : 'true', content : $t('rooms.unassign')}"
                                    class="remove-amenity-button"
                                    className="--secondary --outline --mini --big-text"
                                    :onclick="()=>toggleDeleteAmenity(props.row)">
                                    <font-awesome-icon :icon="['far', 'do-not-enter']"/>
                                </Button>
                            </div>
                            <span v-else style="text-transform: capitalize;">{{
                                    props.formattedRow[props.column.field]
                                }}</span>
                        </template>
                    </vue-good-table>
                </div>

            </FormGroupTwo>

        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormInputPassword from "../../components/form/FormInputPassword";
import ConfirmModal from "@/components/modal/ConfirmModal";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import FormGroupThree from "../../components/form/FormGroupThree";
import UsersCreateModal from "../../components/users/UsersCreateModal";
import AssignAmenityModal from "../../components/rooms/AssignAmenityModal";

export default {
    name: "rooms-update-page",
    components: {
        FormGroupThree,
        FormGroupTwo,
        FormInputText,
        FormInputSelect,
        Button,
        SectionHeader,
        Form,
        Headbar,
    },
    data: function () {

        let amenitiesColumns = [
            {
                label: this.$t('rooms.assigned_amenities'),
                field: 'attributes.name',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update rooms']))
            amenitiesColumns.push({
                label: '',
                field: 'after',
                tdClass: 'td-after',
                sortable: false,
            });

        return {
            room: {
                name: null,
                capacity: null,
                size: null,
            },

            original: null,

            roomImages: [],
            attachmentsToDelete: [],
            is_loading_room_images: false,
            amenitiesColumns: amenitiesColumns,
            amenities: [],
            amenities_records: 0,
            amenitiesServerParams: {sorting: []},
            is_loading_amenities: false,

            is_saving: false,
            is_loading: true,
            files_uploaded: false,
        }
    },
    validations: {
        room: {
            name: {required},
            capacity: {required},
            size: {required},
        }
    },
    methods: {
        async populate() {
            if (!this.original) return;

            this.$v.room.name.$model = this.original.attributes.name;
            this.$v.room.capacity.$model = this.original.attributes.capacity;
            this.$v.room.size.$model = this.original.attributes.size;

            this.is_loading = false;
        },

        async retrieveOriginalRoom() {
            await this.$axios.get(`rooms/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original = data.data
                    this.amenities = data.data.relationships.amenities.data
                })
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        updateParamsAmenities(newProps) {
            this.amenitiesServerParams = Object.assign({}, this.amenitiesServerParams, newProps);
        },

        onPageChangeAmenities(params) {
            this.updateParamsAmenities({page: params.currentPage});
            this.retrieveAmenities();
        },

        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveRooms();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveRooms();
        },

        toggleDeleteAmenity(amenity) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('rooms.unassign'),
                    message: this.$t('rooms.prompt_unassign'),
                    confirmText: this.$t('rooms.unassign'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.put(`rooms/${this.$route.params.id}/amenities/${amenity.id}/detach`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.$notify({
                                        text: this.$t('amenities.success_detached'),
                                        type: 'success',
                                    });
                                    this.retrieveOriginalRoom();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('rooms.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },

        toggleAddAmenity() {
            this.$modal.show(
                AssignAmenityModal, {
                    room_id: this.$route.params.id,
                    amenities_list: this.amenities,
                },
                {
                    name: 'assign-amenity-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveOriginalRoom();
                    }
                }
            );
        },

        async retrieveRoomImages() {
            this.is_loading_room_images = true;
            await this.$axios.get(`rooms/${this.$route.params.id}/documents`)
                .then(({data}) => {
                    if (data.data.length) {
                        this.roomImages = data.data.map(d => ({
                            id: d.id,
                            name: d.attributes.name,
                            preview: d.attributes.image_path,
                            file: null,
                        }));
                    }

                    this.is_loading_room_images = false;
                })
                .catch(e => {
                    this.is_loading_room_images = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_documents_retrieve')),
                        type: 'error',
                    });
                });
        },

        removeAttachment(index) {
            if (index < 0 || index >= this.roomImages.length)
                return;

            if (this.roomImages[index].id)
                this.attachmentsToDelete.push(this.roomImages[index].id);

            this.roomImages.splice(index, 1);
        },

        handleFileChange(e) {
            const files = Array.from(e.target.files);

            files.forEach(async file => {
                this.roomImages.push({
                    file: file,
                    preview: await this.getBase64FromFile(file),
                    name: file.name,
                });
            });
        },

        getBase64FromFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        uploadFile(room_id, attachment, name, attachmentInfo) {
            let formData = new FormData();
            formData.append('name', name);
            formData.append('document', attachment);

            return this.$axios.post(`rooms/${room_id}/documents`, formData).then(({data}) => {
                attachmentInfo = data.id
            });
        },

        async deleteFile(room_id, id) {
            return this.$axios.delete(`rooms/${room_id}/documents/${id}`).then(() => {
                let indexOfPictureId = this.attachmentsToDelete.indexOf(id)
                this.attachmentsToDelete.splice(indexOfPictureId, 1);
            });
        },

        async save() {
            this.$v.room.$touch();
            if (this.$v.room.$anyError || this.is_saving)
                return;

            let payload = Object.assign({}, this.$v.room.$model);

            this.is_saving = true;

            this.$axios.patch(`rooms/${this.$route.params.id}`, payload).then(async ({data}) => {
                const room_id = this.$route.params.id;
                const filePromises = [];

                if (this.roomImages.filter(a => !a.id).length || this.attachmentsToDelete.length) {

                    this.roomImages.filter(a => !a.id).forEach(attachment => {
                        filePromises.push(this.uploadFile(room_id, attachment.file, attachment.name, attachment));
                    });

                    this.attachmentsToDelete.forEach(attachment => {
                        filePromises.push(this.deleteFile(room_id, attachment));
                    });

                    await Promise.all(filePromises).then(() => {
                        this.$notify({
                            text: this.$t('rooms.success_updated'),
                            type: 'success',
                        });

                        this.is_saving = false;
                    }).catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('error_save_files'),
                            type: 'error',
                        });
                    });

                    await this.retrieveOriginalRoom();
                    await this.populate();
                    await this.retrieveRoomImages();

                } else {
                    this.$notify({
                        text: this.$t('rooms.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;

                    await this.retrieveOriginalRoom();
                    await this.populate();
                    await this.retrieveRoomImages();
                }

            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('rooms.error_update')),
                    type: 'error',
                });
            });
        },
    },

    async mounted() {
        this.is_loading = true;
        await this.retrieveOriginalRoom();
        await this.populate();
        await this.retrieveRoomImages();
        this.is_loading = false;
    },

    head() {
        return {
            title: {
                inner: this.$t('rooms.room')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.vgt-custom {
    @apply w-max rounded-t;
    width: max-content !important;
}

.add-amenity-button-container {
    @apply w-full flex flex-row;

    .add-amenity-button {
        @apply h-8 pr-4 w-4 ml-auto mr-10 flex flex-row justify-center items-center;
    }

}


.page-container {

    .section-container-wrapper {
        @apply mx-0;
    }

    .section-container {
        @apply border-b-2 border-primary py-7 px-8 w-full;

        &.half {
            @apply w-1/2 border-b-0;
        }


        h2 {
            @apply text-black font-bold text-2xl pb-4;
        }

    }

    main {
        @apply flex flex-col flex-wrap p-0;

        .section-container {

            &.half {
                @apply w-full;

                @screen xl {
                    @apply w-1/2;
                }
            }

            .file-upload-container {
                @apply w-full h-full flex cursor-pointer;

                .upload-button {
                    @apply flex flex-col m-auto;

                    & > svg {
                        @apply text-5xl mb-4 text-primary;
                    }

                    & > p {
                        @apply text-xl font-bold;
                    }
                }


            }

            .vgt-wrap {
                @apply w-full;

                @screen lg {
                    @apply ml-4;
                    width: calc(100% - 1rem);
                }

                &:nth-child(odd) {
                    @screen lg {
                        @apply mr-4;
                    }
                }

                &:nth-child(even) {
                    @screen lg {
                        @apply ml-4;
                    }
                }

                .td-after {
                    @apply flex flex-row;

                    .remove-amenity-button {
                        @apply ml-auto border-0;
                        border-width: 0px !important;
                    }

                    svg {
                        @apply text-negative text-xl cursor-pointer ml-auto ;

                    }
                }
            }
        }

        .section-container:last-child {
            @apply border-b-0;
        }


        .form {
            @apply mb-8 w-full;

            .form-body {
                @apply px-8 py-7;

                .image-section {

                    .images {

                        .images-container {
                            @apply flex flex-row flex-wrap -m-3;

                            .image-wrapper {
                                @apply p-3 w-1/2;

                                @screen 2xl {
                                    @apply w-1/4;
                                }

                                .image-container {
                                    @apply border-2 border-grey-light rounded p-2 relative;

                                    img {
                                        @apply rounded;
                                    }

                                    .btn-delete {
                                        @apply border-2 border-grey-light rounded flex flex-row items-center justify-center h-5 w-5 absolute right-0 top-0 bg-white;

                                        margin-top: -0.625rem;
                                        margin-right: -0.625rem;

                                        svg {
                                            @apply text-black;

                                            font-size: .7rem;
                                        }

                                        &:active, &:focus {
                                            @apply outline-none;
                                        }
                                    }
                                }
                            }
                        }

                        .upload-row {
                            @apply w-full flex flex-row justify-end mt-5;

                            @screen sm {
                                @apply w-1/4;
                                min-width: 160px;
                            }

                            label {
                                @apply py-3 px-2 mt-1 w-full font-bold rounded border-2 border-grey-light bg-grey-light flex flex-row items-center ml-auto;

                                svg {
                                    @apply ml-auto text-black text-xl;
                                }

                                p {
                                    @apply mr-auto text-black font-bold text-sm ml-3;
                                }
                            }
                        }
                    }

                }

                .upload {
                    @apply w-full;

                    @screen sm {
                        @apply w-1/4;
                        min-width: 160px;
                    }

                    label {
                        @apply py-3 px-2 mt-1 w-full font-bold rounded border-2 border-grey-light bg-grey-light flex flex-row items-center ml-auto;

                        svg {
                            @apply ml-auto text-black text-xl;
                        }

                        p {
                            @apply mr-auto text-black font-bold text-sm ml-3;
                        }
                    }
                }

                input[type="file"] {
                    @apply hidden;
                }

                &.next-of-kin {
                    .next-of-kin-row-group {
                        .input-group {
                            @apply w-auto flex-1;
                        }

                        .button-remove {
                            @apply flex-none self-start mt-9;
                        }
                    }
                }
            }

            .row-add-container {
                @apply px-8 pb-8 mt-auto;
            }
        }
    }
}
</style>