<template>
    <div class="amenities-update-modal">
        <ModalContainer :title="$t('amenities.update_amenity')" identifier="amenities-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.amenity.name.$model" identifier="name" :label="$t('amenities.name')"
                               :placeholder="$t('amenities.name')" :disabled="is_saving"
                               :has-error="$v.amenity.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.amenity.name.required">
                            {{ $t('validation.x_is_required', {x: $t('amenities.name')}) }}
                        </p>
                    </template>
                </FormInputText>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import _ from "lodash";

export default {
    name: "AmenitiesUpdateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            amenity: {
                name: null,
            },
            original: null,
            is_saving: false,
            is_loading_original: false,
        }
    },
    validations: {
        amenity: {
            name: {required},
        }
    },
    props: {
        amenity_id: {
            type: Number,
            required: true
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('amenities-update-modal', status);
        },

        save() {
            this.$v.amenity.$touch();
            if (this.$v.amenity.$anyError || this.is_saving)
                return;

            if (this.amenity.name === this.original.attributes.name) {
                return;
            }


            this.is_saving = true;

            const payload = Object.assign({}, this.$v.amenity.$model);

            this.$axios.patch(`amenities/${this.amenity_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('amenities.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('amenities.error_create')),
                    type: 'error',
                });
            });
        },

        async retrieveOriginalAmenity() {
            this.is_loading_original = false;
            await this.$axios.get(`amenities/${this.amenity_id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('amenities.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        populate() {
            if (!this.original || !this.amenity)
                return;

            this.$v.amenity.name.$model = this.original.attributes.name;
        },


    },
    async mounted(){
        await this.retrieveOriginalAmenity();
        this.populate();
    }
}
</script>
