<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small"
                        :onclick="toggleCreate" v-if="$store.getters.hasPermission('store rooms')">
                    {{ $t('rooms.add_room') }}
                </Button>
                <Button v-if="$store.getters.hasRole('organisation')" className="--primary --small"
                        :onclick="toggleCreateBookingOrganisation">
                    {{ $t('bookings.book') }}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('rooms.search_rooms')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>

            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('rooms.search_rooms')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                ref="table"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="rooms"
                :isLoading.sync="is_loading_rooms"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('rooms.rooms')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'role'">
                        <p style="text-transform: capitalize;">
                            {{ props.row.relationships.role.data[0].attributes.name }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <router-link :to="{name: 'rooms-update', params: {id: props.row.id}}">
                            <Button
                                v-if="$store.getters.hasAnyPermission(['update rooms']) && !$store.getters.hasRole('organisation')"
                                className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        </router-link>

                        <Button
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)" v-if="$store.getters.hasAnyPermission(['destroy rooms'])">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>

                            <Button
                                v-if="$store.getters.hasAnyPermission(['read rooms']) && $store.getters.hasRole('organisation')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleViewRoom(props.row)">
                                <font-awesome-icon :icon="['fal', 'eye']"/>
                            </Button>
                    </div>
                    <span v-else style="text-transform: capitalize;">
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import UsersCreateModal from "../../components/users/UsersCreateModal";
import UsersUpdateModal from "../../components/users/UsersUpdateModal";
import RoomsCreateModal from "../../components/rooms/RoomsCreateModal";
import RoomsBookModal from "../../components/rooms/RoomsBookModal";
import BookingsCreateModalOrganisation from "../../components/bookings/BookingsCreateModalOrganisation";
import BookingRequestModal from "../../components/bookings/BookingRequestModal";

export default {
    name: "rooms-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('rooms.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('rooms.capacity'),
                field: 'attributes.capacity',
                sortable: false,
            },
            {
                label: this.$t('rooms.size'),
                field: 'attributes.size',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['read rooms', 'update rooms', 'destroy rooms']))
            columns.push({
                label: this.$t('actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            rooms: [],
            is_loading_rooms: false,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        toggleDelete(room) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('rooms.delete_room'),
                    message: this.$t('rooms.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`rooms/${room.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveRooms();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data.join(','), this.$t('rooms.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },

        toggleViewRoom(room) {
            this.$modal.show(
                RoomsBookModal, {
                    room_id: room.id
                },
                {
                    name: 'rooms-book-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: '600px',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.toggleBookRoom(room)
                        }
                    }
                }
            );
        },

        toggleBookRoom(room) {
            this.$modal.show(
                BookingsCreateModalOrganisation, {
                    room_id: room.id
                },
                {
                    name: 'bookings-create-modal-organisation',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {

                        }
                    }
                }
            );
        },

        toggleCreate() {
            this.$modal.show(
                RoomsCreateModal, {},
                {
                    name: 'rooms-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveRooms();
                    }
                }
            );
        },

        toggleCreateBookingOrganisation() {
            this.$modal.show(
                BookingsCreateModalOrganisation, {},
                {
                    name: 'bookings-create-modal-organisation',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.$modal.show(
                                BookingRequestModal, {},
                                {
                                    name: 'booking-request-modal',
                                    adaptive: true,
                                    resizable: true,
                                    height: 'auto',
                                    scrollable: true,
                                    classes: 'modal',
                                }, {
                                    'before-close': (e) => {
                                        this.retrieveBookings()
                                    }
                                }
                            )
                        }
                    }
                }
            );
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveRooms();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveRooms();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveRooms();
        },

        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm, page: 1});
            else this.removeParam('term');

            this.retrieveRooms();
        },
        retrieveRooms() {
            this.is_loading_rooms = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('rooms', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.rooms = data.data;
                    this.totalRecords = data.meta.total;
                    this.$refs.table.changePage(data.meta.current_page);
                    this.is_loading_rooms = false;
                })
                .catch(e => {
                    this.is_loading_rooms = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrieveRooms();
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.rooms')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
