<template>
    <div class="rooms-book-modal">
        <LoadingScreen v-if="is_loading_room"/>
        <ModalContainer v-else :title="room.attributes.name" identifier="rooms-book-modal" :closable="true">
            <div class="room-info-container">
                <div class="misc-info">
                    <div class="info-container">
                        <p class="title">{{$t('rooms.capacity')}}</p>
                        <p class="information">{{room.attributes.capacity}}</p>
                    </div>
                    <div class="info-container">
                        <p class="title">{{$t('rooms.size')}}</p>
                        <p class="information">{{room.attributes.size}}</p>
                    </div>
                </div>

                <div class="amenities-info" v-if="room.relationships.amenities.data.length > 0">
                    <div class="info-container">
                        <p class="title">{{$t('amenities.amenities')}}</p>
                        <p class="information">{{room.relationships.amenities.data.map(x => x.attributes.name).join(', ')}}</p>
                    </div>
                </div>

                <div class="images-info" v-if="roomImages">
                    <carousel :perPage="1" :mouseDrag="true" :navigationEnabled="true" paginationColor="#C4C4C4" paginationActiveColor="#FFC829">
                        <slide v-for="(image,index) in roomImages" :key="index">
                            <img class="carousel-image" :src="image.preview"/>
                        </slide>
                    </carousel>
                </div>
            </div>

            <div class="buttons-container">
                <Button type="submit" className="--outline --secondary --small" :onclick="() => close(false)">
                    {{ $t('cancel') }}
                </Button>
                <Button type="submit" className="--primary --small" :onclick="() => close(true)">
                    {{ $t('bookings.book') }}
                </Button>
            </div>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormInputDateTime from "../form/FormInputDateTime";
import LoadingScreen from "../LoadingScreen";
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: "RoomsBookModal",
    components: {
        LoadingScreen, FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer, FormInputDateTime, Carousel, Slide},
    data() {
        return {
            room: null,
            roomImages: null,
            is_loading_room: true,
        }
    },

    props: {
        room_id: {
            type: Number,
            required: false,
            default: null,
        }
    },

    methods: {
        close(status) {
            this.$modal.hide('rooms-book-modal', status);
        },

        async retrieveRoom() {
            this.is_loading_room = true;
            await this.$axios.get(`rooms/${this.room_id}`)
                .then(({data}) => {
                    this.room = data.data;
                    this.is_loading_room = false;
                })
                .catch(e => {
                    this.is_loading_room = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveRoomImages() {
            this.is_loading_room_images = true;
            await this.$axios.get(`rooms/${this.room_id}/documents`)
                .then(({data}) => {
                    if (data.data.length) {
                        this.roomImages = data.data.map(d => ({
                            id: d.id,
                            name: d.attributes.name,
                            preview: d.attributes.image_path,
                            file: null,
                        }));
                    }
                    this.is_loading_room_images = false;
                })
                .catch(e => {
                    this.is_loading_room_images = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_documents_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted(){
        await this.retrieveRoom();
        await this.retrieveRoomImages();
    }
}
</script>

<style lang="scss" scoped>
.rooms-book-modal {

    .info-container {
        @apply flex flex-col mb-4;

        .title {
            @apply font-bold mb-2;
        }

        .information {
            @apply text-sm;
        }

    }

    .carousel-image {
        @apply rounded-lg;

        object-fit: fill;
        aspect-ratio: 16 / 9;
        max-height: 24rem;
        width: 100%;
    }

    .room-info-container{
        @apply mt-2;

        .misc-info {
            @apply flex flex-row;

            .info-container {
                @apply w-1/2;
            }

        }
    }

    .amenities-info {
        @apply mb-8;
    }

    .buttons-container {
        @apply flex flex-row justify-between mt-4;
    }
}
</style>
