<template>
    <div class="search-container">
        <form @submit.prevent="search" class="search">
            <input v-model="searchTerm" :placeholder="placeholder"/>
            <button type="submit" class="nebtn">
                <font-awesome-icon :icon="['far', 'search']"/>
            </button>
        </form>
    </div>
</template>

<script>
    export default {
        name: "Search",
        props: {
            placeholder: {
                type: String,
                required: false,
                default: function () {
                    return this.$t("search")
                }
            }
        },
        data() {
            return {
                searchTerm: '',
            }
        },
        methods: {
            search() {
                this.$emit('search', this.searchTerm);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .search-container {
        form.search {
            @apply ml-auto self-center flex flex-row justify-between items-center border-2 border-secondary rounded flex-1 p-0;
            min-width: 15rem;
            max-width: 20rem;

            & > input {
                @apply bg-transparent appearance-none px-4 py-3 text-black text-sm flex-1;

                &::placeholder {
                    @apply text-black text-sm;
                    opacity: 1;
                }

                &:-ms-input-placeholder {
                    @apply text-black text-sm;
                }

                &::-ms-input-placeholder {
                    @apply text-black text-sm;
                }

                &:active, &:focus {
                    @apply outline-none;
                }
            }

            & > button {
                @apply bg-transparent px-3;
                margin: 0 !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                align-self: center !important;

                svg {
                    @apply text-base text-black;
                }
            }
        }
    }
</style>