<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small" class="generate-report-button" :onclick="generateReport"
                        :disabled="is_printing">
                    {{ $t('reports.generate_report') }}
                </Button>
                <div class="report-type-container">
                    <p class="filter-desktop">{{ $t('reports.report_type') }}:</p>
                    <FormInputSelect v-model="selectedReportType" identifier="report_type"
                                     :options="reportTypeOptions" @input="onFilterUpdated"
                                     class="report-type-select filter-desktop" :allow-empty="false"
                                     :placeholder="$t('reports.report_type')" :disabled="is_loading"
                                     track-by="id" display-label="name"/>
                </div>
            </template>
            <template v-slot:right>
                <FormInputDateTime v-model="dateTimeFrom" identifier="from_date" class="filter-desktop"
                                   :placeholder="$t('reports.from_date')" @input="onFilterUpdated"
                                   :disabled="is_printing" :minute-interval="30"/>

                <FormInputDateTime v-model="dateTimeTo" identifier="to_date" class="filter-desktop"
                                   :placeholder="$t('reports.to_date')" @input="onFilterUpdated"
                                   :disabled="is_printing" :minute-interval="30"/>
            </template>

            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <FormInputDateTime v-model="dateTimeFrom" identifier="from_date"
                                       :placeholder="$t('reports.from_date')" @input="onFilterUpdated"
                                       :disabled="is_printing" :minute-interval="30" class=""/>

                    <FormInputDateTime v-model="dateTimeTo" identifier="to_date"
                                       :placeholder="$t('reports.to_date')" @input="onFilterUpdated"
                                       :disabled="is_printing" :minute-interval="30" class=""/>
                </div>
            </template>
        </Headbar>

        <main>
            <div>
                <vue-good-table
                    styleClass="vgt-table vgt-custom"
                    :columns="currentColumns"
                    :rows="reportData"
                    :isLoading.sync="is_loading_reports"
                    :search-options="{enabled: false,}"
                    :pagination-options="{enabled: false,}"
                    :sort-options="{ enabled: true, multipleColumns: true,}"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                    <template slot="table-row" slot-scope="props">
                    </template>
                </vue-good-table>
                <vue-html2pdf :float-layout="true"
                              :enable-download="true"
                              :filename="pdfFileName"
                              :pdf-quality="2"
                              :manual-pagination="true"
                              pdf-format="a4"
                              pdf-orientation="landscape"
                              pdf-content-width="100%"
                              @beforeDownload="beforeDownload($event)"
                              @hasDownloaded="hasDownloaded($event)"
                              ref="html2Pdf">

                    <section slot="pdf-content" class="pdf-content">
                        <div class="pdf-content-container" v-for="index in totalPages" :key="index">
                            <div class="pdf-content-header">
                                <h1>{{ $t(`reports.${currentReportName}`) }} - Page {{ index }}/{{ totalPages }}</h1>
                                <h2 v-if="dateTimeFrom && dateTimeTo">{{ $t('reports.time_frame') }}:<span>{{$moment(dateTimeFrom).format('DD/MM/YYYY - HH:mm') }} to </span><span>{{ $moment(dateTimeTo).format('DD/MM/YYYY - HH:mm') }}</span></h2>
                            </div>

                            <vue-good-table
                                styleClass="vgt-table vgt-custom vgt-custom-print"
                                :columns="currentColumns"
                                :rows="reportData"
                                :isLoading.sync="is_loading_reports"
                                :search-options="{enabled: false,}"
                                :pagination-options="{enabled: true, mode: 'records', perPage: 10, setCurrentPage: index,}"
                                :sort-options="{ enabled: true, multipleColumns: true,}"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange">
                                <template slot="table-row" slot-scope="props">
                                </template>
                            </vue-good-table>
                            <div v-if="index !== totalPages" class="html2pdf__page-break"/>
                        </div>
                    </section>
                </vue-html2pdf>
            </div>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import FormInputSelect from "../../components/form/FormInputSelect";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import VueHtml2pdf from 'vue-html2pdf'

export default {
    name: "reports-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar, FormInputSelect, FormInputDateTime, VueHtml2pdf},
    data: function () {
        return {
            organisationColumns: [
                {
                    label: this.$t('organisations.organisation'),
                    field: 'Organisation Name',
                    sortable: false,
                    width: '10%',
                },
                {
                    label: this.$t('organisations.address'),
                    field: 'Address',
                    sortable: false,
                    width: '10%',
                },
                {
                    label: this.$t('organisations.contact_person'),
                    field: 'Contact Person',
                    sortable: false,
                    width: '10%',
                },
                {
                    label: this.$t('organisations.email'),
                    field: 'Email',
                    sortable: false,
                    width: '10%',
                },
                {
                    label: this.$t('organisations.phone'),
                    field: 'Phone',
                    sortable: false,
                    width: '10%',
                },
                {
                    label: this.$t('reports.total_number_of_bookings'),
                    field: 'Total Bookings',
                    sortable: false,
                    width: '10%',

                },
                {
                    label: this.$t('reports.total_number_of_hours_booked'),
                    field: 'Total Hours Booked',
                    sortable: false,
                    width: '10%',
                },
            ],

            roomColumns: [
                {
                    label: this.$t('rooms.id'),
                    field: 'ID',
                    sortable: false,
                    width: '10%',
                },
                {
                    label: this.$t('rooms.room'),
                    field: 'Room Name',
                    sortable: false,
                    width: '10%',
                },
                {
                    label: this.$t('rooms.capacity'),
                    field: 'Room Capacity',
                    sortable: false,
                    width: '10%',
                },
                {
                    label: this.$t('rooms.size'),
                    field: 'Room Size',
                    sortable: false,
                    width: '10%',
                },
                {
                    label: this.$t('reports.total_number_of_bookings'),
                    field: 'Total No of Bookings',
                    sortable: false,
                    width: '10%',

                },
                {
                    label: this.$t('reports.total_number_of_hours_booked'),
                    field: 'Total Hours Booked',
                    sortable: false,
                    width: '10%',
                },
            ],

            currentColumns: [],
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false,
            is_printing: false,
            is_loading: false,

            selectedReportType: {
                id: 1,
                name: 'Organisations Report',
                value: 'organisations',
                title: 'organisations_report'
            },
            reportTypeOptions: [{
                id: 1,
                name: 'Organisations Report',
                value: 'organisations',
                title: 'organisations_report'
            },
                {id: 2,
                 name: 'Rooms Report',
                 value: 'rooms',
                 title: 'rooms_report'}],

            reportData: [],
            is_loading_reports: false,

            is_loading_organisations: false,
            organisationOptions: [],
            selectedOrganisation: null,
            is_loading_organisation_reports: false,
            organisationRows: null,
            organisationTotalRecords: null,
            organisationTotalPages: null,

            is_loading_rooms: false,
            roomOptions: [],
            selectedRoom: null,
            is_loading_room_reports: false,
            roomRows: null,
            roomTotalRecords: null,
            roomTotalPages: null,

            dateTimeFrom: null,
            dateTimeTo: null,
            totalRecords: null,
            totalPages: null,
        }
    },
    methods: {
        async generateReport() {
            if (!this.dateTimeFrom) {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$t('reports.choose_a_from_date'),
                    type: 'error',
                });
                return;
            }

            if (!this.dateTimeTo) {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$t('reports.choose_a_to_date'),
                    type: 'error',
                });
                return;
            }

            if (this.dateTimeTo <= this.dateTimeFrom) {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$t('reports.to_date_must_be_after_from'),
                    type: 'error',
                });
                return;
            }

            this.is_printing = true;
            await this.$refs.html2Pdf.generatePdf()
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({page: params.currentPage});
        },

        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
        },

        removeParam(param) {
            this.$delete(this.serverParams, param);
        },

        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveReport();
        },

        async beforeDownload() {
            this.is_printing = true;
        },

        async hasDownloaded() {
            this.is_printing = false;
        },

        retrieveOrganisations() {
            this.is_loading_organisations = true;

            this.$axios.get('organisations/list')
                .then(({data}) => {
                    this.organisationOptions = data.data;
                    this.is_loading_organisations = false;
                })
                .catch(e => {
                    this.is_loading_organisations = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('organisations.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        retrieveRooms() {
            this.is_loading_rooms = true;

            this.$axios.get('rooms/list')
                .then(({data}) => {
                    this.roomOptions = data.data;
                    this.is_loading_rooms = false;
                })
                .catch(e => {
                    this.is_loading_rooms = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        onFilterUpdated() {
            const filters = {};

            if (this.selectedReportType) {
                if (this.selectedReportType.id === 1)
                    this.currentColumns = this.organisationColumns
                else if (this.selectedReportType.id === 2)
                    this.currentColumns = this.roomColumns
            }

            if (this.dateTimeFrom) {
                filters['from'] = this.$moment(this.dateTimeFrom).format('YYYY-MM-DD HH:mm:ss');
            }

            if (this.dateTimeTo) {
                filters['to'] = this.$moment(this.dateTimeTo).format('YYYY-MM-DD HH:mm:ss');
            }

            if (filters)
                this.updateParams({...filters})
            else
                this.removeParam('filters');

            if (filters.from && filters.to && this.selectedReportType)
                this.retrieveReport();

        },

        retrieveReport() {
            this.is_loading_reports = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get(`reports/${this.selectedReportType.id}`, {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.reportData = data;
                    this.totalRecords = data.length;
                    this.totalPages = Math.ceil(this.totalRecords / 10);
                    this.is_loading_reports = false;
                })
                .catch(e => {
                    this.is_loading_reports = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        },

    },

    mounted() {
        this.currentColumns = this.organisationColumns;
    },

    computed: {
        pdfFileName() {
            return `${this.selectedReportType.name} ${this.$moment().format('YYYY-MM-DD')}`;
        },

        currentReportName() {
            return this.selectedReportType.title
        }
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.reports')
            },
        }
    }
}
</script>
<style lang="scss" scoped>

.pdf-content {
    @apply px-4 h-full;

    .pdf-content-container {
        @apply flex flex-col h-full;

        ::v-deep .vgt-clearfix {
            display: none !important;
        }

        .pdf-content-header {
            @apply mb-8;

            h1 {
                @apply text-3xl font-bold mb-4;
            }

            h2 {
                @apply text-lg font-bold my-4;
            }

            span {
                @apply font-normal;
            }

            p {
                @apply mt-auto;
            }
        }
    }
}


.page-container {

    .headbar-container {
        @apply gap-y-4 pb-2;
    }


    .report-type-container {
        @apply flex flex-row items-center justify-between gap-x-4 text-sm;
    }

    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .input-group {
        @apply mb-0;
        margin-bottom: 0;
        width: 170px;
        height: 50px;

        &.report-type-select {
            width: 170px;
        }


        &.mobile-margin-fix {
            @apply mx-auto;
            width: 241px;
            padding-right: 4px;

            &.no-padding {
                @apply pr-0;
            }

            .date-time-picker {
                width: 241px;
            }
        }
    }

    ::v-deep .field-input {
        @apply w-full;
        height: 50px !important;
        min-height: 50px !important;


        @screen sm {
            margin-bottom: 0 !important;
            width: 170px !important;
            min-width: 170px !important;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }

        ::v-deep .date-time-picker{
            @apply bg-negative;

            .datetimepicker {
                .datepicker{
                    right: 0;
                }
            }
        }
    }

    .search-container {
        @apply mx-auto;

        @screen md {
            @apply ml-2;
        }

    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
