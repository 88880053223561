<template>
    <div class="rooms-create-modal">
        <ModalContainer :title="$t('rooms.add_room')" identifier="rooms-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.room.name.$model" identifier="name" :label="$t('rooms.name')"
                                   :placeholder="$t('rooms.name')" :disabled="is_saving"
                                   :has-error="$v.room.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.room.name.required">
                                {{ $t('validation.x_is_required', {x: $t('rooms.name')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.room.capacity.$model" identifier="capacity" :label="$t('rooms.capacity')"
                                   :placeholder="$t('rooms.capacity')" :disabled="is_saving"
                                   :has-error="$v.room.capacity.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.room.capacity.required">
                                {{ $t('validation.x_is_required', {x: $t('rooms.capacity')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputText v-model="$v.room.size.$model" identifier="size" :label="$t('rooms.size')"
                                   :placeholder="$t('rooms.size')" :disabled="is_saving"
                                   :has-error="$v.room.size.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.room.size.required">
                                {{ $t('validation.x_is_required', {x: $t('rooms.size')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <div class="upload">
                        <label for="property-create-file-upload">
                            <font-awesome-icon :icon="['fal','upload']"/>
                            <p>{{ $t('rooms.upload_images') }}</p>
                        </label>
                    </div>
                    <input type="file" name="file" id="property-create-file-upload" accept="image/*" multiple
                           @change="handleFileChange" :disabled="is_saving"/>
                </FormGroupTwo>

                <div v-if="roomImages.length" class="image-section">
                    <div class="images-container">
                        <div v-for="(image, index) in roomImages" class="image-wrapper">
                            <div class="image-container">
                                <button type="button" class="btn-delete" @click="removeAttachment(index)">
                                    <font-awesome-icon :icon="['far','times']"/>
                                </button>
                                <img :src="image.preview"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Button type="submit" className="--primary --small" :class="{'spinner-black': is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "RoomsCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            room: {
                name: null,
                capacity: null,
                size: null,
            },
            roomImages: [],
            is_saving: false,
            is_loading_roles: false,
            roleOptions: []
        }
    },
    validations: {
        room: {
            name: {required},
            capacity: {required},
            size: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('rooms-create-modal', status);
        },
        uploadFile(room_id, attachment, name) {
            let formData = new FormData();
            formData.append('name', name);
            formData.append('document', attachment);

            return this.$axios.post(`rooms/${room_id}/documents`, formData);
        },
        save() {
            this.$v.room.$touch();
            if (this.$v.room.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.room.$model);

            this.$axios.post(`rooms`, payload).then(async ({data}) => {
                if (this.roomImages.filter(a => !a.id).length) {
                    const filePromises = [];

                    this.roomImages.filter(a => !a.id).forEach(attachment => {
                        filePromises.push(this.uploadFile(data.data.id, attachment.file, attachment.name));
                    });

                    await Promise.all(filePromises).then(() => {
                    }).catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('error_save_files'),
                            type: 'error',
                        });
                    });
                }

                this.$notify({
                    text: this.$t('rooms.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('rooms.error_create')),
                    type: 'error',
                });
            });
        },
        handleFileChange(e) {
            const files = Array.from(e.target.files);

            files.forEach(async file => {
                this.roomImages.push({
                    file: file,
                    preview: await this.getBase64FromFile(file),
                    name: file.name,
                });
            });
        },
        getBase64FromFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        removeAttachment(index) {
            if (index < 0 || index >= this.roomImages.length)
                return;

            this.roomImages.splice(index, 1);
        },
    }
}
</script>

<style lang="scss" scoped>
.upload {
    @apply flex flex-row justify-end mt-5 w-1/2 px-2;

    label {
        @apply py-3 mt-1 w-full font-bold rounded border-2 border-grey bg-grey flex flex-row items-center ml-auto;

        svg {
            @apply ml-auto text-black text-xl;
        }

        p {
            @apply mr-auto text-black font-bold text-sm ml-3;
        }
    }
}

.image-section {
    .images-container {
        @apply flex flex-row flex-wrap mt-4 mb-5;

        .image-wrapper {
            @apply p-3 w-1/4;

            .image-container {
                @apply border-2 border-grey-light rounded p-2 relative;

                img {
                    @apply rounded;
                }

                .btn-delete {
                    @apply border-2 border-grey-light rounded flex flex-row items-center justify-center h-5 w-5 absolute right-0 top-0 bg-white;

                    margin-top: -0.625rem;
                    margin-right: -0.625rem;

                    svg {
                        @apply text-black;

                        font-size: .7rem;
                    }

                    &:active, &:focus {
                        @apply outline-none;
                    }
                }
            }
        }
    }

    .upload-row {
        @apply flex flex-row justify-end mt-5;

        label {
            @apply py-4 px-7 font-bold rounded border-2 border-primary flex flex-row items-center ml-auto;

            svg {
                @apply text-black text-xl;
            }

            p {
                @apply text-black font-bold text-sm ml-3;
            }
        }
    }
}

input[type="file"] {
    @apply hidden;
}

</style>