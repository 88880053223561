<template>
    <div class="assign-amenity-modal">
        <ModalContainer :title="$t('rooms.assign_amenity')" identifier="assign-amenity-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputSelect v-model="$v.amenity.amenity.$model" identifier="room"
                                 :label="$t('amenities.name')" :options="amenityOptions"
                                 :placeholder="$t('amenities.name')" :disabled="is_saving || is_loading_amenities"
                                 :has-error="$v.amenity.amenity.$error" track-by="id"
                                 :display-custom-label="(row) => `${row.attributes.name}`" :multiple="false" class="select">
                    <template v-slot:errors>
                        <p v-if="!$v.amenity.amenity.required">
                            {{ $t('validation.x_is_required', {x: $t('amenities.amenity')}) }}
                        </p>
                    </template>
                </FormInputSelect>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('assign') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "AssignAmenityModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            amenity: {
                amenity: null,
            },
            amenityOptions: [],
            originalAmenities: [],
            is_saving: false,
            is_loading_amenities: false,
        }
    },

    props: {
        room_id: {
            type: Number,
            required: true,
        },
        amenities_list: {
            type: Array,
            required: true,
        }
    },

    validations: {
        amenity: {
            amenity: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('assign-amenity-modal', status);
        },

        save() {
            this.$v.amenity.$touch();
            if (this.$v.amenity.$anyError || this.is_saving) return;
            this.is_saving = true;

            this.$axios.put(`rooms/${this.room_id}/amenities/${this.amenity.amenity.id}/attach`).then(({data}) => {
                this.$notify({
                    text: this.$t('amenities.success_assigned'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('amenities.error_create')),
                    type: 'error',
                });
            });
        },

        async retrieveAmenityOptions() {
            this.is_loading_amenities = true;
            await this.$axios.get('amenities/list')
                .then(({data}) => {
                    this.amenityOptions = data.data;
                    this.is_loading_amenities = false;
                })
                .catch(e => {
                    this.is_loading_amenities = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async filterAmenityOptions() {
            this.is_loading_amenities = true;
            let tempOriginalAmenities = this.originalAmenities
            this.amenityOptions = this.amenityOptions.filter((val) => !(tempOriginalAmenities.find(val2 => val2.id === val.id)))
            this.is_loading_amenities = false
        }
    },

    async mounted() {
        this.originalAmenities = this.amenities_list
        await this.retrieveAmenityOptions();
        await this.filterAmenityOptions();

    }
}
</script>
