<template>
    <div class="booking-cancelled-modal">
        <ModalContainer :title="$t('bookings.cancel_booking')" identifier="booking-cancelled-modal" :closable="true">
            <div class="booking-cancelled-container">
                <p>{{ $t('bookings.your_booking_has_been_cancelled') }}</p>

                <font-awesome-icon :icon="['fal','check-circle']"/>
                <div class="submit-row">
                    <Button className="--primary" :onclick="() => close(true)">
                        {{ $t('okay') }}
                    </Button>
                </div>
            </div>

        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormInputDateTime from "../form/FormInputDateTime";

export default {
    name: "BookingCancelledModal",
    components: {
        FormInputPassword,
        Button,
        FormInputSelect,
        FormGroupTwo,
        FormInputText,
        Form,
        ModalContainer,
        FormInputDateTime
    },
    methods: {
        close(status) {
            this.$modal.hide('booking-cancelled-modal', status);
        },
    },
}
</script>

<style lang="scss" scoped>
.booking-cancelled-container {
    @apply flex flex-col items-center;

    & > p {
        @apply text-black font-medium text-base text-center max-w-92 mt-2;
    }

    svg {
        @apply text-secondary text-8xl my-12;
    }

    .submit-row {
        @apply flex flex-row justify-between items-center w-full;

        button {
            @apply mx-auto cursor-pointer;
        }
    }
}
</style>



