<template>
    <div class="bookings-view-modal">
        <ModalContainer :title="$t('bookings.view_booking')" identifier="bookings-view-modal" :closable="true">
            <div class="booking-information-container">
                <div class="two-container">

                <div class="info-container">
                    <p class="title">{{$t('bookings.room')}}</p>
                    <p class="information">{{booking.room.attributes.name}}</p>
                </div>

                <div class="info-container">
                    <p class="title">{{$t('bookings.status')}}</p>
                    <p class="information">{{booking.status}}</p>
                </div>
                </div>


                <div class="two-container">

                <div class="info-container">
                    <p class="title">{{$t('bookings.from_date')}}</p>
                    <p class="information">{{$moment(booking.from).format('ddd, MMM DD, YYYY hh:mm A')}}</p>
                </div>

                <div class="info-container">
                    <p class="title">{{$t('bookings.to_date')}}</p>
                    <p class="information">{{$moment(booking.to).format('ddd, MMM DD, YYYY hh:mm A')}}</p>
                </div>

                </div>

                <div class="info-container">
                    <p class="title">{{$t('bookings.comments')}}</p>
                    <p class="information" v-if="booking.comments">{{booking.comments}}</p>
                    <p class="information" v-else>No Comments Yet</p>
                </div>

            </div>
            <Button :onclick="cancelBooking" className="--primary --small cancel-button" :class="{spinner: is_saving}" :disabled="(!original) || (original && original.attributes.status === 'cancelled')">
                {{ $t('bookings.cancel_booking') }}
            </Button>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormInputDateTime from "../form/FormInputDateTime";
import _ from "lodash";
import ConfirmModal from "../modal/ConfirmModal";
import BookingCancelledModal from "./BookingCancelledModal";

export default {
    name: "BookingsViewModal",
    components: {
        FormInputPassword,
        Button,
        FormInputSelect,
        FormGroupTwo,
        FormInputText,
        Form,
        ModalContainer,
        FormInputDateTime,
        ConfirmModal
    },
    data() {
        return {
            booking: {
                room: null,
                to: null,
                from: null,
                status: null,
                organisation: null,
                comments: null,

            },
            statusOptions: [
                {id: 1, name: 'Accepted', value: 'accepted'},
                {id: 2, name: 'Pending', value: 'pending'},
                {id: 3, name: 'Rejected', value: 'rejected'},
                {id: 4, name: 'Cancelled', value: 'cancelled'}],
            original: null,
            roomOptions: [],
            organisationOptions: [],
            booking_status: null,
            is_saving: false,
            is_loading_original: false,
            is_loading_rooms: false,
            is_loading_organisations: false,
            is_time_equal: false,
            is_date_different: false,
        }
    },
    validations: {
        booking: {
            room: {required},
            to: {required},
            from: {required},
            organisation: {required},
            status: {required},
            comments: {},

        }
    },
    props: {
        booking_id: {
            type: Number,
            required: true
        }
    },

    methods: {
        close(status) {
            this.$modal.hide('bookings-view-modal', status);
        },
        async populate() {
            if (!this.original || !this.booking)
                return;

            this.$v.booking.from.$model = this.original.attributes.from;
            this.$v.booking.to.$model = this.original.attributes.to;
            this.$v.booking.room.$model = this.original.relationships.room.data;
            this.$v.booking.status.$model = this.original.attributes.status;

            if (this.original.attributes.comments)
                this.$v.booking.comments.$model = this.original.attributes.comments;

            this.booking_status = _.find(this.statusOptions, {value: this.original.attributes.status})
        },

        cancelBooking() {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('bookings.cancel_booking'),
                    message: this.$t('bookings.prompt_cancel'),
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            const payload = Object.assign({}, this.$v.booking.$model);
                            payload.status = 'cancelled'

                            this.$axios.patch(`bookings/${this.booking_id}`, payload)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.close(true);
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('bookings.error_cancel')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },

        async retrieveRoomOptions() {
            this.is_loading_rooms = false;
            await this.$axios.get('rooms')
                .then(({data}) => {
                    this.roomOptions = data.data;
                    this.is_loading_rooms = false;
                })
                .catch(e => {
                    this.is_loading_rooms = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveOrganisationOptions() {
            this.is_loading_organisations = false;
            await this.$axios.get('organisations')
                .then(({data}) => {
                    this.organisationOptions = data.data;
                    this.is_loading_organisations = false;
                })
                .catch(e => {
                    this.is_loading_organisations = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('organisations.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveOriginalBooking() {
            this.is_loading_original = true;
            await this.$axios.get(`bookings/${this.booking_id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        },
        minReturnDate() {
            return this.$moment(this.booking.from).add(1, 'hours').format('YYYY-MM-DD HH:mm');
        },
        maxReturnDate() {
            return this.$moment(this.booking.from).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
    async mounted() {
        await this.retrieveOriginalBooking();
        await this.populate();
    }
}
</script>

<style lang="scss" scoped>
.info-container {
    @apply flex flex-col mb-4 w-1/2;
    text-transform: capitalize;

    .title {
        @apply font-bold mb-2;
    }

    .information {
        @apply text-sm;
    }

}

.two-container {
    @apply flex flex-row;
}

.cancel-button {
    @apply mt-4;
}

.status-select-container {
    @apply flex flex-row items-center ml-auto -mr-3;

    p {
        @apply mr-4 text-xs;
    }

    .input-group {
        @apply mb-0 w-40 mr-4;
    }
}
</style>
