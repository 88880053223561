<template>
    <div class="bookings-create-modal">
        <ModalContainer :title="$t('bookings.add_booking')" identifier="bookings-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputSelect v-model="$v.booking.room.$model" identifier="room"
                                     :label="$t('bookings.room')" :options="roomOptions"
                                     :placeholder="$t('bookings.room')" :disabled="is_saving"
                                     :has-error="$v.booking.room.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`" class="select">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.room.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.room')}) }}
                            </p>
                        </template>
                    </FormInputSelect>
                    <FormInputSelect v-model="$v.booking.organisation.$model" identifier="organisation"
                                     :label="$t('bookings.organisation')" :options="organisationOptions"
                                     :placeholder="$t('bookings.organisation')" :disabled="is_saving"
                                     :has-error="$v.booking.organisation.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.organisation}`" class="select">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.organisation.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.organisation')}) }}
                            </p>
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputDateTime v-model="$v.booking.from.$model" identifier="from" :label="$t('bookings.from_date')"
                                       :placeholder="$t('bookings.from_date')" :disabled="is_saving" :minute-interval="30" className="center"
                                       :has-error="$v.booking.from.$error || is_time_equal || is_date_different" :min-date="todayDate">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.from.required">
                                {{$t('validation.x_is_required',{x: $t('bookings.from_date')})}}
                            </p>
                            <p v-else-if="is_time_equal">
                                {{$t('bookings.time_cant_be_same')}}
                            </p>
                            <p v-else-if="is_date_different">
                                {{$t('bookings.date_cant_be_different')}}
                            </p>
                        </template>
                    </FormInputDateTime>
                    <FormInputDateTime v-model="$v.booking.to.$model" identifier="to" :label="$t('bookings.to_date')"
                                       :placeholder="$t('bookings.to_date')" :disabled="is_saving" :minute-interval="30" className="center"
                                       :has-error="$v.booking.to.$error" :min-date="minReturnDate" :max-date="maxReturnDate">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.to.required">
                                {{$t('validation.x_is_required',{x: $t('bookings.to_date')})}}
                            </p>
                        </template>
                    </FormInputDateTime>
                </FormGroupTwo>
                <FormInputText v-model="$v.booking.comments.$model" :label="$t('bookings.comments')"
                               :placeholder="$t('bookings.comments')" :disabled="is_saving"
                               :use-textarea="true" :large-textarea="true"
                               :has-error="$v.booking.comments.$error">
                    <template v-slot:errors>
                    </template>
                </FormInputText>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormInputDateTime from "../form/FormInputDateTime";

export default {
    name: "BookingsCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer, FormInputDateTime},
    data() {
        return {
            booking: {
                room: null,
                to: null,
                from: null,
                organisation: null,
                comments: null,

            },
            roomOptions: [],
            organisationOptions: [],
            is_saving: false,
            is_loading_rooms: false,
            is_loading_organisations: false,
            is_time_equal: false,
            is_date_different: false,
        }
    },

    validations: {
        booking: {
            room: {required},
            to: {required},
            from: {required},
            organisation: {required},
            comments: {},
        }
    },

    methods: {
        close(status) {
            this.$modal.hide('bookings-create-modal', status);
        },

        save() {
            this.is_time_equal = false;
            this.is_date_different = false;
            this.$v.booking.$touch();
            if (this.$v.booking.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.booking.$model);
            payload.room_id = this.booking.room.id
            payload.organisation_id = this.booking.organisation.id
            payload.status = 'pending'

            if(payload.from === payload.to){
                this.is_time_equal = true;
                this.is_saving = false;
                return;
            }

            if(payload.from.substring(0,10) !== payload.to.substring(0,10)){
                this.is_date_different = true;
                this.is_saving = false;
                return;
            }

            if (this.booking.comments)
                payload.comments = this.booking.comments

            this.$axios.post(`rooms/${this.booking.room.id}/book`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('bookings.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.message, this.$t('bookings.error_create')),
                    type: 'error',
                });
            });
        },

        async retrieveRoomOptions() {
            this.is_loading_rooms = false;
            await this.$axios.get('rooms/list')
                .then(({data}) => {
                    this.roomOptions = data.data;
                    this.is_loading_rooms = false;
                })
                .catch(e => {
                    this.is_loading_rooms = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveOrganisationOptions() {
            this.is_loading_organisations = false;
            await this.$axios.get('organisations/list')
                .then(({data}) => {
                    this.organisationOptions = data.data;
                    this.is_loading_organisations = false;
                })
                .catch(e => {
                    this.is_loading_organisations = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('organisations.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        },
        minReturnDate() {
            return this.$moment(this.booking.from).add(1, 'hours').format('YYYY-MM-DD HH:mm');
        },
        maxReturnDate() {
            return this.$moment(this.booking.from).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
    async mounted(){
        await this.retrieveRoomOptions();
        await this.retrieveOrganisationOptions();
    }
}
</script>

<style lang="scss" scoped>

</style>
