export default {
    nav: {
        home: 'Home',
        login: 'Login',
        reset_password: 'Reset Password',
        amenities: 'Amenities',
        bookings: 'Bookings',
        rooms: 'Rooms',
        organisations: 'Organisations',
        reports: 'Reports',
        calendar: 'Calendar',
        roles_permissions: 'Roles & Permissions',
        users: 'Users',
        profile: 'Profile',
        logout: 'Logout',
        signup: 'Sign Up',
    },
    amenities: {
        name: 'Name',
        amenity: 'Amenity',
        amenities: 'Amenities',
        add_amenity: 'Add Amenity',
        search_amenities: 'Search Amenities',
        update_amenity: 'Update Amenity',
        delete_amenity: 'Delete Amenity',
        prompt_delete: 'Are you sure you want to delete this amenity?',
        error_delete: 'Failed to delete amenity',
        error_retrieve: 'Failed to retrieve amenities',
        error_create: 'Failed to create amenity',
        error_update: 'Failed to update amenity',
        success_delete: 'Amenity has been deleted',
        success_created: 'Amenity has been created',
        success_updated: 'Amenity has been updated',
        success_assigned: 'Amenity has been assigned',
        success_detached: 'Amenity has been detached',

        cannot_delete_amenity: 'Cannot delete amenity as it is currently in use by one or more rooms.'
    },
    bookings: {
        booking: 'Booking',
        bookings: 'Bookings',
        book: 'Book',
        booking_number: 'Booking Number',
        room: 'Room',
        date: 'Date',
        time_slot: 'Time Slot',
        organisation: 'Organisation',
        comments: 'Comments',
        status: 'Status',
        from_date: 'From Date',
        to_date: 'To Date',
        add_booking: 'Add Booking',
        update_booking: 'Update Booking',
        search_bookings: 'Search Bookings',
        delete_booking: 'Delete Booking',
        cancel_booking: 'Cancel Booking',
        view_booking: 'View Booking',
        prompt_cancel: 'Are you sure you want to cancel this booking?',
        prompt_delete: 'Are you sure you want to delete this booking?',
        error_delete: 'Failed to delete booking',
        error_retrieve: 'Failed to retrieve bookings',
        error_create: 'Failed to create booking',
        error_update: 'Failed to update booking',
        error_cancel: 'Failed to cancel booking',
        success_delete: 'Booking has been deleted',
        success_created: 'Booking has been created',
        success_cancelled: 'Booking has been cancelled',
        success_update: 'Booking has been updated',
        your_booking_has_been_cancelled: 'Your booking has been cancelled.',
        your_booking_request_has_been_sent: 'Your booking request has been sent.',
        new_booking: 'New Booking',
        no_bookings_message: 'No bookings have been made yet. To make a booking click the book button above.',
        date_cant_be_different: 'Dates must be on the same day',
        time_cant_be_same: 'Times must be at least 1 hour apart',
    },

    rooms: {
        id: 'ID',
        name: 'Name',
        capacity: 'Capacity',
        size: 'Size',
        rooms: 'Rooms',
        room: 'Room',
        unassign: 'Un-assign',
        assign_amenity: 'Assign Amenity',
        assigned_amenities: 'Assigned Amenities',
        add_room: 'Add Room',
        search_rooms: 'Search Rooms',
        room_details: 'Room Details',
        upload_images: 'Upload Images',
        update_room: 'Update Room',
        delete_room: 'Delete Room',
        prompt_delete: 'Are you sure you want to delete this room?',
        prompt_unassign: 'Are you sure you want to un-assign this amenity?',
        error_delete: 'Failed to delete room',
        error_retrieve: 'Failed to retrieve rooms',
        error_create: 'Failed to create room',
        error_update: 'Failed to update room',
        success_delete: 'Room has been deleted',
        success_created: 'Room has been created',
        success_updated: 'Room has been updated',
        cannot_delete_room: 'Cannot delete room as there are bookings, amenities and pictures related to it.'
    },

    organisations: {
        organisation: 'Organisation',
        organisations: 'Organisations',
        contact_person: 'Contact Person',
        phone: 'Phone',
        email: 'Email',
        address: 'Address',
        role: 'Role',
        add_organisation: 'Add Organisation',
        invite_organisation: 'Invite Organisation',
        search_organisations: 'Search Organisations',
        update_organisation: 'Update Organisation',
        delete_organisation: 'Delete Organisation',
        prompt_delete: 'Are you sure you want to delete this organisation?',
        error_delete: 'Failed to delete organisation',
        error_retrieve: 'Failed to retrieve organisations',
        error_create: 'Failed to create organisation',
        error_update: 'Failed to update organisation',
        success_delete: 'Organisation has been deleted',
        success_created: 'Organisation has been created',
        success_updated: 'Organisation has been updated',
        cannot_delete_organisation: 'Cannot delete organisation as there are bookings related to it.'

    },
    profile: {
        profile: 'profile',
        user_account: 'User Account',
        personal_information: 'Personal Information',
        address_information: 'Address Information',
        change_password: 'Change Password',
        success_updated: 'Your Account has been updated',
        error_update: 'Failed to update profile'
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search Users',
        name: 'Name',
        company: 'Company',
        mobile_number: 'Mobile Number',
        phone_mobile_number: 'Phone / Mobile Number',
        address: 'Address',
        email: 'Email',
        phone: 'Phone',
        email_address: 'Email Address',
        organisation: 'Organisation',
        company_name: 'Company Name',
        contact_person: 'Contact Person',
        vat_number: 'VAT Number',
        city: 'City',
        post_code: 'Post Code',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this user?',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        user_details: 'User Details',
        update_user: 'Update User',
        delete_user: 'Delete User',
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search Roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'role has been created',
        success_updated: 'role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    reports: {
        generate_report: 'Generate Report',
        report_type: 'Report Type',
        room_name: 'Room Name',
        organisation_name: 'Organisation Name',
        from_date: 'From Date',
        to_date: 'To Date',
        choose_a_room: 'Choose a room',
        choose_an_organisation: 'Choose an organisation',
        choose_a_from_date: 'Choose a from date',
        choose_a_to_date: 'Choose a to date',
        report_options: 'Report Options',
        preview: 'Preview',
        organisations_report: 'Organisations Report',
        rooms_report: 'Rooms Report',
        total_number_of_bookings: 'Total number of bookings',
        total_number_of_hours_booked: 'Total number of hours booked',
        time_frame: 'Time Frame',
        to_date_must_be_after_from :'The to date must be after the from date',
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
        passwords_must_match: 'Passwords must match',
        password_must_be_atleast_x_characters: 'Password must be at least {x} characters',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        setup_your_account: 'Setup your account',
        account_created: 'Account successfully created',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password!',
        failed_signup: 'Signup Failed',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    start: 'Start',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    print: 'Print',
    send: 'Send',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    actions: 'Actions',
    back: 'Back',
    submit: 'Submit',
    assign: 'Assign',
    okay: 'Okay',
    signup: 'Sign Up',
}
