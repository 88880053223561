<template>
    <aside :class="['sidebar-container', {'organisation-sidebar': hasRoleOrganisation}]">
        <router-link :to="{name: 'amenities-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read amenities') && !$store.getters.hasRole('organisation')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'icons-alt']"/>
            </div>
            <p>{{$t('nav.amenities')}}</p>
        </router-link>
        <router-link :to="{name: 'bookings-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read bookings')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'book-open']"/>
            </div>
            <p>{{$t('nav.bookings')}}</p>
        </router-link>
        <router-link :to="{name: 'calendar-index'}" class="nav-item"
                     v-if="!$store.getters.hasRole('organisation') && $store.getters.hasPermission('read bookings')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'calendar']"/>
            </div>
            <p>{{$t('nav.calendar')}}</p>
        </router-link>
        <router-link :to="{name: 'rooms-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read rooms')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'house']"/>
            </div>
            <p>{{$t('nav.rooms')}}</p>
        </router-link>
        <router-link :to="{name: 'organisations-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read organisations')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'building']"/>
            </div>
            <p>{{$t('nav.organisations')}}</p>
        </router-link>
        <router-link :to="{name: 'reports-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read reports')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'file-chart-pie']"/>
            </div>
            <p>{{$t('nav.reports')}}</p>
        </router-link>
        <router-link :to="{name: 'roles-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read roles')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{$t('nav.roles_permissions')}}</p>
        </router-link>
        <router-link :to="{name: 'users-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read users')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{$t('nav.users')}}</p>
        </router-link>
    </aside>
</template>

<script>
    export default {
        name: "Sidebar",

        computed: {
            hasRoleOrganisation() {
                return this.$store.getters.hasRole('organisation');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sidebar-container {
        @apply h-full flex flex-col bg-white overflow-y-auto hidden justify-between py-4;

        box-shadow: 5px 0px 10px #0000001A;
        width: 130px;
        grid-area: sidebar;

        @screen md {
            @apply flex;
        }

        @screen 3xl {
            @apply py-8;
        }

        &.organisation-sidebar {
            @apply justify-start;

            .nav-item {
                @apply mb-8;
            }
        }

        .nav-item {
            @apply flex flex-col items-center;

            .icon-container {
                @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
                height: 40px;
                width: 40px;

                svg {
                    @apply text-black w-auto;
                    height: 18px;
                }
            }

            p {
                @apply text-black text-xs font-bold mt-2 text-center;

                @screen 3xl {
                    @apply text-sm mt-4;
                }
            }

            &:hover, &:focus {
                .icon-container {
                    @apply border-secondary;
                }
            }

            &.router-link-active {
                .icon-container {
                    @apply bg-secondary border-secondary;

                    svg {
                        @apply text-white;
                    }
                }
            }
        }
    }
</style>