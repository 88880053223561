<template>
    <nav class="menubar-container">
        <button class="btn-mobile-menu" @click="$store.commit('TOGGLE_MENU_EXPANDED')">
            <font-awesome-icon :icon="['fal', 'bars']"/>
        </button>

        <img src="../assets/white-logo.svg" class="logo">

        <div class="user-wrapper">
            <div class="user">
                <div class="avatar">
                    <user-icon iconType="fas" iconName="user" backgroundColor="secondary"></user-icon>
<!--                    <avatar background-color="#C82026" color="#474747" :size="30" :username="$store.getters.user.attributes.name"></avatar>-->
                </div>
                <p>{{$store.getters.user.attributes.contact_person}}</p>
                <font-awesome-icon :icon="['far', 'angle-down']"/>
            </div>
            <nav>
                <ul class="menu">
                    <li>
                        <router-link :to="{name: 'profile'}">{{$t('nav.profile')}}</router-link>
                    </li>
                    <li>
                        <a @click="logout">{{$t('nav.logout')}}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </nav>
</template>

<script>
    import Avatar from 'vue-avatar'
    import UserIcon from './UserIcon'

    export default {
        name: "Menubar",
        components: {UserIcon, Avatar},
        methods: {
            async logout() {
                await this.$store.dispatch('logout');
                this.$router.push({name: 'login'});
            },
        }
    }
</script>

<style lang="scss" scoped>
.menubar-container {
    @apply flex flex-row w-full bg-black h-14 text-white relative justify-between;
    z-index: 999999;
    grid-area: menubar;

    .btn-mobile-menu {
        @apply ml-6;

        @screen md {
            @apply hidden;
        }

        svg {
            @apply text-white text-xl;
        }

        &:active,&:focus{
            @apply outline-none;
        }
    }

    img.logo {
        @apply h-full w-auto pt-1 pl-4;
    }

    .user-wrapper {
        @apply py-4 cursor-pointer mr-2;

        .user {
            @apply mx-4 flex flex-row h-9 pb-4 items-center;

            .avatar {
                @apply mr-3;
            }

            p {
                @apply max-w-xs truncate text-sm hidden mr-3;

                @screen md {
                    @apply block;
                }
            }

            svg {
                @apply text-2xl;
            }
        }

        nav {
            @apply relative hidden bg-grey-darker mt-1;
            z-index: 999;

            .menu {
                @apply p-0 list-none;

                li {
                    a {
                        @apply flex text-white cursor-pointer py-4 px-6 text-sm;
                    }

                    &:hover {
                        background: linear-gradient(90deg, theme('colors.primary') 2%, #666666 2%);
                    }
                }
            }
        }

        &:hover, &:active, &:focus {
            nav {
                @apply block;
            }
        }
    }
}
</style>