<template>
    <div class="organisations-update-modal">
        <ModalContainer :title="$t('organisations.update_organisation')" identifier="organisations-update-modal"
                        :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.organisation.contact_person.$model" identifier="name"
                                   :label="$t('organisations.contact_person')"
                                   :placeholder="$t('organisations.contact_person')" :disabled="is_saving"
                                   :has-error="$v.organisation.contact_person.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.organisation.contact_person.required">
                                {{ $t('validation.x_is_required', {x: $t('organisations.contact_person')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.organisation.organisation.$model" identifier="name"
                                   :label="$t('organisations.organisation')"
                                   :placeholder="$t('organisations.organisation')" :disabled="is_saving"
                                   :has-error="$v.organisation.organisation.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.organisation.organisation.required">
                                {{ $t('validation.x_is_required', {x: $t('organisations.organisation')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <FormInputText v-model="$v.organisation.address.$model" identifier="name"
                               :label="$t('organisations.address')"
                               :placeholder="$t('organisations.address')" :disabled="is_saving"
                               :has-error="$v.organisation.address.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.organisation.address.required">
                            {{ $t('validation.x_is_required', {x: $t('organisations.address')}) }}
                        </p>
                    </template>
                </FormInputText>
                <FormGroupTwo>
                    <FormInputText v-model="$v.organisation.email.$model" identifier="name"
                                   :label="$t('organisations.email')"
                                   :placeholder="$t('organisations.email')" :disabled="is_saving"
                                   :has-error="$v.organisation.email.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.organisation.email.required">
                                {{ $t('validation.x_is_required', {x: $t('organisations.email')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.organisation.phone.$model" identifier="name"
                                   :label="$t('organisations.phone')"
                                   :placeholder="$t('organisations.phone')" :disabled="is_saving"
                                   :has-error="$v.organisation.phone.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.organisation.phone.required">
                                {{ $t('validation.x_is_required', {x: $t('organisations.phone')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import _ from "lodash";

export default {
    name: "OrganisationsUpdateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            organisation: {
                contact_person: null,
                organisation: null,
                address: null,
                email: null,
                phone: null,
            },
            original: null,
            is_saving: false,
            is_loading_original: false,
        }
    },
    validations: {
        organisation: {
            contact_person: {required},
            organisation: {required},
            address: {required},
            email: {required},
            phone: {required},
        }
    },
    props: {
        organisation_id: {
            type: Number,
            required: true
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('organisations-update-modal', status);
        },
        save() {
            this.$v.organisation.$touch();
            if (this.$v.organisation.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.organisation.$model);

            if (this.original.attributes.contact_person === payload.contact_person)
                delete payload.contact_person;

            if (this.original.attributes.organisation === payload.organisation)
                delete payload.organisation;

            if (this.original.attributes.address === payload.address)
                delete payload.address;

            if (this.original.attributes.email === payload.email)
                delete payload.email;

            if (this.original.attributes.phone === payload.phone)
                delete payload.phone;

            this.$axios.patch(`organisations/${this.organisation_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('organisations.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('organisations.error_create')),
                    type: 'error',
                });
            });
        },
        async retrieveOriginalOrganisation() {
            this.is_loading_original = false;
            await this.$axios.get(`organisations/${this.organisation_id}`)
                .then(async ({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                    this.populate();
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        populate() {
            this.organisation = _.clone(this.original.attributes);
        }
    },

    async mounted(){
        await this.retrieveOriginalOrganisation()
    }
}
</script>
