import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import RolesIndex from "../views/roles/Index";
import UsersIndex from "../views/users/Index";
import AmenitiesIndex from "../views/amenities/Index"
import BookingsIndex from "../views/bookings/Index"
import RoomsIndex from "../views/rooms/Index"
import RoomsUpdate from "../views/rooms/Update"
import CalendarIndex from "../views/calendar/Index"
import OrganisationsIndex from "../views/organisations/Index"
import ReportsIndex from "../views/reports/Index"
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import Profile from "../views/Profile";
import {i18n} from '../locale/i18n';
import Reset from "@/views/Reset";
import SignUp from "../views/SignUp";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/calendar'
    },
    {
        path: '/amenities',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'amenities-index',
                component: AmenitiesIndex,
                meta: {
                    auth: true,
                    check_if_organisation: true,
                    // all_permissions: ['read amenities'],
                },
            },
        ]
    },
    {
        path: '/bookings',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'bookings-index',
                component: BookingsIndex,
                meta: {
                    auth: true,
                    // all_permissions: ['read bookings'],
                },
            },
        ]
    },
    {
        path: '/calendar',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'calendar-index',
                component: CalendarIndex,
                meta: {
                    auth: true,
                    check_if_organisation: true,
                    // all_permissions: ['read bookings'],
                },
            },
        ]
    },
    {
        path: '/rooms',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'rooms-index',
                component: RoomsIndex,
                meta: {
                    auth: true,
                    // all_permissions: ['read rooms'],
                },
            },
            {
                path: ':id',
                name: 'rooms-update',
                component: RoomsUpdate,
                meta: {
                    auth: true,
                    // all_permissions: ['read rooms'],
                },
            },
        ]
    },
    {
        path: '/organisations',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'organisations-index',
                component: OrganisationsIndex,
                meta: {
                    auth: true,
                    check_if_organisation: true,
                    // all_permissions: ['read organisations'],
                },
            },
        ]
    },
    {
        path: '/reports',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'reports-index',
                component: ReportsIndex,
                meta: {
                    auth: true,
                    check_if_organisation: true,
                    // all_permissions: ['read reports'],
                },
            },
        ]
    },
    {
        path: '/roles',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'roles-index',
                component: RolesIndex,
                meta: {
                    auth: true,
                    check_if_organisation: true,
                    // all_permissions: ['read roles'],
                },
            },
        ]
    },
    {
        path: '/users',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'users-index',
                component: UsersIndex,
                meta: {
                    auth: true,
                    check_if_organisation: true,
                    // all_permissions: ['read users'],
                },
            },
        ]
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            auth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/admins/invite',
        name: 'signup',
        component: SignUp,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/password/reset',
        name: 'reset',
        component: Reset,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound,
        meta: {
            auth: false
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);

    if (!to.meta)
        next();

    // check auth
    if (to.meta.auth) {
        const routeAuth = to.meta.auth;

        if (routeAuth === true) {
            // user must be logged in
            if (!store.getters.token) {
                Vue.notify({
                    type: 'error',
                    text: i18n.t('auth.unauthenticated')
                });

                return next({name: 'login'});
            }
        } else if (routeAuth.toLowerCase() === 'guest') {
            // user must be logged out
            if (store.getters.token)
                return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.any_permissions) {
        // check that the user has some of the required permissions

        if (!store.getters.hasAnyPermission(to.meta.any_permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.all_permissions) {
        // check that the user has all the required permissions

        let permissions = to.meta.all_permissions;
        if (!store.getters.hasAllPermissions(permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    if (to.meta.check_if_organisation) {
        // check if the user's role is 'Organisation', if so, only allow him to access bookings and profile pages.

        if (store.getters.hasRole('organisation')) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/bookings'});
        }
    }

    return next();
});

export default router;
