<template>
    <div class="organisations-create-modal">
        <ModalContainer :title="$t('organisations.invite_organisation')" identifier="organisations-create-modal"
                        :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.organisation.contact_person.$model" identifier="name"
                                   :label="$t('organisations.contact_person')"
                                   :placeholder="$t('organisations.contact_person')" :disabled="is_saving"
                                   :has-error="$v.organisation.contact_person.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.organisation.contact_person.required">
                                {{ $t('validation.x_is_required', {x: $t('organisations.contact_person')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.organisation.organisation.$model" identifier="name"
                                   :label="$t('organisations.organisation')"
                                   :placeholder="$t('organisations.organisation')" :disabled="is_saving"
                                   :has-error="$v.organisation.organisation.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.organisation.organisation.required">
                                {{ $t('validation.x_is_required', {x: $t('organisations.organisation')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputText v-model="$v.organisation.email.$model" identifier="name"
                                   :label="$t('organisations.email')"
                                   :placeholder="$t('organisations.email')" :disabled="is_saving"
                                   :has-error="$v.organisation.email.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.organisation.email.required">
                                {{ $t('validation.x_is_required', {x: $t('organisations.email')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputSelect v-model="$v.organisation.role.$model" identifier="role"
                                     :label="$t('organisations.role')" :options="roleOptions"
                                     :placeholder="$t('organisations.role')" :disabled="is_saving"
                                     :has-error="$v.organisation.role.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.organisation.role.required">
                                {{$t('validation.x_is_required',{x: $t('organisations.role')})}}
                            </p>
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "OrganisationsCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            organisation: {
                contact_person: null,
                organisation: null,
                email: null,
                role: null,
            },
            is_saving: false,
            is_loading_roles: false,
            roleOptions: [],
        }
    },
    validations: {
        organisation: {
            contact_person: {required},
            organisation: {required},
            email: {required},
            role: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('organisations-create-modal', status);
        },

        save() {
            this.$v.organisation.$touch();
            if (this.$v.organisation.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.organisation.$model);

            payload.role = payload.role.id;

            this.$axios.post(`organisations/invite`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('organisations.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('organisations.error_create')),
                    type: 'error',
                });
            });
        },

        async retrieveRoleOptions() {
            this.is_loading_roles = false;
            await this.$axios.get('roles/list')
                .then(({data}) => {
                    this.roleOptions = data.data;
                    this.is_loading_roles = false;
                })
                .catch(e => {
                    this.is_loading_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },

    async mounted(){
        await this.retrieveRoleOptions()
    }
}
</script>
