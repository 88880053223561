<template>
    <div class="amenities-create-modal">
        <ModalContainer :title="$t('amenities.add_amenity')" identifier="amenities-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.amenity.name.$model" identifier="name" :label="$t('amenities.name')"
                               :placeholder="$t('amenities.name')" :disabled="is_saving"
                               :has-error="$v.amenity.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.amenity.name.required">
                            {{ $t('validation.x_is_required', {x: $t('amenities.name')}) }}
                        </p>
                    </template>
                </FormInputText>
                <Button type="submit" className="--primary --small" :class="{'spinner-black': is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "AmenitiesCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            amenity: {
                name: null,
            },
            is_saving: false,
        }
    },
    validations: {
        amenity: {
            name: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('amenities-create-modal', status);
        },
        save() {
            this.$v.amenity.$touch();
            if (this.$v.amenity.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.amenity.$model);

            this.$axios.post(`amenities`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('amenities.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('amenities.error_create')),
                    type: 'error',
                });
            });
        },
    },
}
</script>
