<template>
    <div class="bookings-update-modal">
        <ModalContainer :title="$t('bookings.update_booking')" identifier="bookings-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputSelect v-model="$v.booking.room.$model" identifier="room"
                                     :label="$t('bookings.room')" :options="roomOptions"
                                     :placeholder="$t('bookings.room')" :disabled="false"
                                     :has-error="$v.booking.room.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`" class="select">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.room.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.room')}) }}
                            </p>
                        </template>
                    </FormInputSelect>
                    <FormInputSelect v-model="$v.booking.organisation.$model" identifier="organisation"
                                     :label="$t('bookings.organisation')" :options="organisationOptions"
                                     :placeholder="$t('bookings.organisation')"
                                     :disabled="true"
                                     :has-error="$v.booking.organisation.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.organisation}`" class="select">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.organisation.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.organisation')}) }}
                            </p>
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputDateTime v-model="$v.booking.from.$model" identifier="from"
                                       :label="$t('bookings.from_date')"
                                       :placeholder="$t('bookings.from_date')"
                                       :disabled="false" :minute-interval="30"
                                       :has-error="$v.booking.from.$error || is_time_equal || is_date_different"
                                       :min-date="todayDate">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.from.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.from_date')}) }}
                            </p>
                            <p v-else-if="is_time_equal">
                                {{ $t('bookings.time_cant_be_same') }}
                            </p>
                            <p v-else-if="is_date_different">
                                {{ $t('bookings.date_cant_be_different') }}
                            </p>
                        </template>
                    </FormInputDateTime>
                    <FormInputDateTime v-model="$v.booking.to.$model" identifier="to" :label="$t('bookings.to_date')"
                                       :placeholder="$t('bookings.to_date')"
                                       :disabled="false" :minute-interval="30"
                                       :has-error="$v.booking.to.$error" :min-date="minReturnDate"
                                       :max-date="maxReturnDate">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.to.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.to_date')}) }}
                            </p>
                        </template>
                    </FormInputDateTime>
                </FormGroupTwo>
                <FormInputText v-model="$v.booking.comments.$model" :label="$t('bookings.comments')"
                               :placeholder="$t('bookings.comments')" :disabled="is_saving"
                               :use-textarea="true" :large-textarea="true"
                               :has-error="$v.booking.comments.$error" autocomplete="off">
                    <template v-slot:errors>
                    </template>
                </FormInputText>
                <FormGroupTwo>
                    <Button type="submit" :disabled="is_loading_original || is_loading_rooms || is_loading_organisations"
                            className="--primary --small" :class="{spinner: is_saving}">
                        {{ $t('save') }}
                    </Button>

                    <div class="status-select-container">
                        <p>{{ $t('bookings.status') }}:</p>
                        <FormInputSelect v-model="booking_status" :placeholder="$t('bookings.status')"
                                         :allow-empty="false"
                                         :options="statusOptions" identifier="name" :disabled="is_saving"
                                         track-by="name" :display-custom-label="(row) => `${row.name}`"/>
                    </div>

                </FormGroupTwo>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormInputDateTime from "../form/FormInputDateTime";
import _ from "lodash";

export default {
    name: "BookingsUpdateModal",
    components: {
        FormInputPassword,
        Button,
        FormInputSelect,
        FormGroupTwo,
        FormInputText,
        Form,
        ModalContainer,
        FormInputDateTime
    },
    data() {
        return {
            booking: {
                room: null,
                to: null,
                from: null,
                organisation: null,
                comments: null,

            },
            statusOptions: [
                {id: 1, name: 'Accepted', value: 'accepted'},
                {id: 2, name: 'Pending', value: 'pending'},
                {id: 3, name: 'Rejected', value: 'rejected'},
                {id: 4, name: 'Cancelled', value: 'cancelled'}],
            original: null,
            roomOptions: [],
            organisationOptions: [],
            booking_status: null,
            is_saving: false,
            is_loading_original: false,
            is_loading_rooms: false,
            is_loading_organisations: false,
            is_time_equal: false,
            is_date_different: false,
        }
    },
    validations: {
        booking: {
            room: {required},
            to: {required},
            from: {required},
            organisation: {required},
            comments: {},
        }
    },
    props: {
        booking_id: {
            type: Number,
            required: true
        }
    },

    methods: {
        close(status) {
            this.$modal.hide('bookings-update-modal', status);
        },
        async populate() {
            if (!this.original || !this.booking)
                return;

            this.$v.booking.from.$model = this.original.attributes.from;
            this.$v.booking.to.$model = this.original.attributes.to;
            this.$v.booking.room.$model = _.find(this.roomOptions, {id: this.original.relationships.room.data.id});
            this.$v.booking.organisation.$model = _.find(this.organisationOptions, {id: this.original.relationships.organisation.data.id});
            this.booking_status = _.find(this.statusOptions, {value: this.original.attributes.status})

            if (this.original.attributes.comments)
                this.$v.booking.comments.$model = this.original.attributes.comments;
        },

        save() {
            this.is_time_equal = false;
            this.is_date_different = false;
            this.$v.booking.$touch();
            if (this.$v.booking.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {}
            payload.room_id = this.booking.room.id
            payload.status = this.booking_status.value

            if (this.booking.comments || !this.booking.comments)
                payload.comments = this.booking.comments

            this.$axios.patch(`bookings/${this.booking_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('bookings.success_update'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('bookings.error_update')),
                    type: 'error',
                });
            });
        },

        async retrieveRoomOptions() {
            this.is_loading_rooms = true;
            await this.$axios.get('rooms/list')
                .then(({data}) => {
                    this.roomOptions = data.data;
                    this.is_loading_rooms = false;
                })
                .catch(e => {
                    this.is_loading_rooms = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveOrganisationOptions() {
            this.is_loading_organisations = true;
            await this.$axios.get('organisations/list')
                .then(({data}) => {
                    this.organisationOptions = data.data;
                    this.is_loading_organisations = false;
                })
                .catch(e => {
                    this.is_loading_organisations = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('organisations.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveOriginalBooking() {
            this.is_loading_original = true;
            await this.$axios.get(`bookings/${this.booking_id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        },
        minReturnDate() {
            return this.$moment(this.booking.from).add(1, 'hours').format('YYYY-MM-DD HH:mm');
        },
        maxReturnDate() {
            return this.$moment(this.booking.from).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
    async mounted() {
        await this.retrieveOriginalBooking();
        await this.retrieveRoomOptions();
        await this.retrieveOrganisationOptions();
        await this.populate();
    }
}
</script>

<style lang="scss" scoped>
.status-select-container {
    @apply flex flex-row items-center ml-auto -mr-3;

    p {
        @apply mr-4 text-xs;
    }

    .input-group {
        @apply mb-0 w-40 mr-4;
    }
}
</style>
