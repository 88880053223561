<template>
    <LoadingScreen v-if="is_loading_bookings && $store.getters.hasRole('organisation')"></LoadingScreen>
    <div v-else class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button v-if="$store.getters.hasRole('administrator')" className="--primary --small"
                        :onclick="toggleCreate">
                    {{ $t('bookings.add_booking') }}
                </Button>

                <Button v-else-if="$store.getters.hasRole('organisation')" className="--primary --small"
                        :onclick="toggleCreate">
                    {{ $t('bookings.book') }}
                </Button>
            </template>
            <template v-slot:right>
                <FormInputSelect v-model="selectedStatus" identifier="user" :options="statusOptions"
                                 class="select-input filter-desktop"
                                 :placeholder="$t('bookings.status')" :disabled="is_loading_bookings"
                                 @updated="onFilterUpdated"
                                 track-by="id" display-label="name"/>

                <Search class="search-desktop" :placeholder="$t('bookings.search_bookings')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>

            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('bookings.search_bookings')"
                            @search="search"/>

                    <FormInputSelect v-model="selectedStatus" identifier="user" :options="statusOptions"
                                     class="mobile-select-input" v-if="headbarExpanded === 'search'"
                                     :placeholder="$t('bookings.status')" :disabled="is_loading_bookings"
                                     @updated="onFilterUpdated"
                                     track-by="id" display-label="name"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                v-if="($store.getters.hasRole('organisation') && bookings && userBookingsLength > 0) || $store.getters.hasRole('administrator')"
                mode="remote"
                ref="table"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="bookings"
                :isLoading="is_loading_bookings"
                :search-options="{enabled: false,}"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('bookings.bookings')}),}"
                :sort-options="{
                    enabled: true,
                    initialSortBy: {field: 'created_at', type: 'desc'}}"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'role'">
                        <p>{{ props.row.relationships.role.data[0].attributes.name }}</p>
                    </div>
                    <div v-if="props.column.field === 'attributes.status'">
                        <p v-if="props.row.attributes.status === 'pending'" class="status pending"
                           style="text-transform: capitalize;">{{ props.row.attributes.status }}</p>
                        <p v-if="props.row.attributes.status === 'rejected'" class="status rejected"
                           style="text-transform: capitalize;">{{ props.row.attributes.status }}</p>
                        <p v-if="props.row.attributes.status === 'cancelled'" class="status cancelled"
                           style="text-transform: capitalize;">{{ props.row.attributes.status }}</p>
                        <p v-if="props.row.attributes.status === 'accepted'" class="status accepted"
                           style="text-transform: capitalize;">{{ props.row.attributes.status }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.from'">
                        <p style="text-transform: capitalize;">
                            {{ $moment(props.row.attributes.from).format('DD/MM/YYYY') }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.time_slot'">
                        <p style="text-transform: capitalize;">
                            {{ getTimeSlot(props.row.attributes.to, props.row.attributes.from) }}</p>
                    </div>

                  <div v-else-if="props.column.field === 'attributes.comments'">
                        <p v-if="props.row.attributes.comments">
                            {{ props.row.attributes.comments }}
                        </p>
                        <p v-else>No Comments Yet</p>
                  </div>

                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleUpdate(props.row)" v-if="$store.getters.hasRole('administrator') && $store.getters.hasAnyPermission(['update bookings'])">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>

                        <Button
                            className="--secondary --outline --mini --big-text"
                            :onclick="()=>toggleDelete(props.row)" v-if="$store.getters.hasRole('administrator') && $store.getters.hasAnyPermission(['destroy bookings'])">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>

                        <Button
                            className="--secondary --outline --mini --big-text"
                            :onclick="()=>toggleView(props.row)" v-if="$store.getters.hasRole('organisation') && $store.getters.hasAnyPermission(['update bookings'])">
                            <font-awesome-icon :icon="['fal', 'eye']"/>
                        </Button>

                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
            <div v-else class="no-bookings-message">
                <p>{{ $t('bookings.no_bookings_message') }}</p>
            </div>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import BookingsCreateModal from "../../components/bookings/BookingsCreateModal";
import BookingsUpdateModal from "../../components/bookings/BookingsUpdateModal";
import BookingsViewModal from "../../components/bookings/BookingsViewModal";
import BookingCancelledModal from "../../components/bookings/BookingCancelledModal";
import BookingsCreateModalOrganisation from "../../components/bookings/BookingsCreateModalOrganisation";
import BookingRequestModal from "../../components/bookings/BookingRequestModal";
import FormInputSelect from "../../components/form/FormInputSelect";
import LoadingScreen from "../../components/LoadingScreen";

export default {
    name: "bookings-index-page",
    components: {LoadingScreen, ConfirmModal, Select, Button, Search, Headbar, FormInputSelect},
    data: function () {
        const columns = [
            {
                label: this.$t('bookings.booking_number'),
                field: 'attributes.reference',
                sortable: false,
            },
            {
                label: this.$t('bookings.room'),
                field: 'relationships.room.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('bookings.date'),
                field: 'attributes.from',
                sortable: false,
            },
            {
                label: this.$t('bookings.time_slot'),
                field: 'attributes.time_slot',
                sortable: false,
            },
            {
                label: this.$t('bookings.organisation'),
                field: 'relationships.organisation.data.attributes.organisation',
                sortable: false,
            },
            {
                label: this.$t('bookings.comments'),
                field: 'attributes.comments',
                sortable: false,
            },
            {
                label: this.$t('bookings.status'),
                field: 'attributes.status',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update bookings', 'destroy bookings']))
            columns.push({
                label: this.$t('actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            bookings: [],
            is_loading_bookings: false,
            totalRecords: null,
            userBookingsLength : 0,
            serverParams: {
                sorting: [],
            },
            searchTerm: null,
            currentSort: 'id',
            currentSortDir: 'desc',
            is_deleting: false,
            headbarExpanded: false,
            selectedStatus: null,
            statusOptions: [
                {id: 1, name: 'Accepted', value: 'accepted'},
                {id: 2, name: 'Pending', value: 'pending'},
                {id: 3, name: 'Rejected', value: 'rejected'},
                {id: 4, name: 'Cancelled', value: 'cancelled'}],
        }
    },
    methods: {
        toggleDelete(booking) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('bookings.delete_booking'),
                    message: this.$t('bookings.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`bookings/${booking.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.$notify({
                                        text: this.$t('bookings.success_delete'),
                                        type: 'success',
                                    });
                                    this.retrieveBookings();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('bookings.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleCreate() {
            if (this.$store.getters.hasRole('organisation'))
                this.createBookingOrganisation();
            else if (this.$store.getters.hasRole('administrator'))
                this.createBookingAdministrator()
        },
        createBookingOrganisation() {
            this.$modal.show(
                BookingsCreateModalOrganisation, {},
                {
                    name: 'bookings-create-modal-organisation',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.$modal.show(
                                BookingRequestModal, {},
                                {
                                    name: 'booking-request-modal',
                                    adaptive: true,
                                    resizable: true,
                                    height: 'auto',
                                    scrollable: true,
                                    classes: 'modal',
                                }, {
                                    'before-close': (e) => {
                                        this.retrieveBookings()
                                    }
                                }
                            )
                        }
                    }
                }
            );
        },
        createBookingAdministrator() {
            this.$modal.show(
                BookingsCreateModal, {},
                {
                    name: 'bookings-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveBookings();
                    }
                }
            );
        },
        toggleUpdate(booking) {
            this.$modal.show(
                BookingsUpdateModal,
                {
                    booking_id: booking.id,
                },
                {
                    name: 'bookings-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveBookings();
                    }
                }
            );
        },
        toggleView(booking) {
            this.$modal.show(
                BookingsViewModal,
                {
                    booking_id: booking.id,
                },
                {
                    name: 'bookings-view-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.$modal.show(
                                BookingCancelledModal, {},
                                {
                                    name: 'booking-cancelled-modal',
                                    adaptive: true,
                                    resizable: true,
                                    height: 'auto',
                                    scrollable: true,
                                    classes: 'modal',
                                }, {
                                    'before-close': (e) => {
                                        this.retrieveBookings()
                                    }
                                }
                            )
                    }
                }
            );
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveBookings();
        },
        onSortChange(params) {
            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveBookings();
            }

            let sort_by = null;
            let sort_order = params[0].type;

            sort_by = 'created_at';
            sort_order = 'desc';

            this.updateParams({sort_by: sort_by, sort_order: sort_order});
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm, page: 1});
            else this.removeParam('term');

            this.retrieveBookings();
        },
        onFilterUpdated() {
            const filters = [];

            if (this.selectedStatus) {
                filters.push({
                    filter_by: 'status',
                    filter_value: this.selectedStatus.value
                })
            }

            if (filters.length)
                this.updateParams({filters: filters})
            else
                this.removeParam('filters');

            this.retrieveBookings();
        },
        retrieveBookings() {
            if (this.$store.getters.hasRole('organisation'))
                this.retrieveBookingsOrganisation();
            else if (this.$store.getters.hasRole('administrator'))
                this.retrieveBookingsAdministrator()
        },
        retrieveBookingsAdministrator() {
            this.is_loading_bookings = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get(`bookings`, {params: {...this.serverParams }})
                .then(({data}) => {
                    this.bookings = data.data;
                    this.totalRecords = data.meta.total;
                    this.$refs.table.changePage(data.meta.current_page);
                    this.is_loading_bookings = false;
                })
                .catch(e => {
                    this.is_loading_bookings = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        retrieveBookingsOrganisation() {
            this.is_loading_bookings = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get(`profile/bookings`, {params: {...this.serverParams, sorting: encodedSorting}})
                .then(async ({data}) => {
                    if (this.userBookingsLength <= 0) {
                        this.userBookingsLength = data.data.length;
                    }
                    this.bookings = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_bookings = false
                    await this.$nextTick(() => {
                        this.$refs.table.changePage(data.meta.current_page);
                    });
                })
                .catch(e => {
                    this.is_loading_bookings = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getTimeSlot(to, from) {
            let fromTime = from.split(" ")[1].substring(0, from.split(" ")[1].length - 3)
            let toTime = to.split(" ")[1].substring(0, to.split(" ")[1].length - 3)

            return `${fromTime} - ${toTime}`
        },
    },
    mounted() {
        this.retrieveBookings();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.bookings')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .no-bookings-message {
        @apply font-bold;
    }

    .input-group {

        &.select-input {
            margin-bottom: 0 !important;
            width: 150px !important;
            height: 50px !important;
            min-height: 50px !important;
        }

        &.mobile-select-input{
            @apply mx-auto;
            width: 241px !important;
            padding-right: 4px;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }

    .status {
        &.accepted {
            @apply text-accepted;
        }

        &.pending {
            @apply text-pending;
        }

        &.rejected {
            @apply text-rejected;
        }

        &.cancelled {
            @apply text-black;
        }
    }
}
</style>
