<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small"
                        :onclick="toggleCreate">
                    {{ $t('users.add_user') }}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('users.search_users')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('users.search_users')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                ref="table"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="users"
                :isLoading.sync="is_loading_users"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('users.users')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'role'">
                        <p style="text-transform: capitalize;">
                            {{ props.row.relationships.role.data[0].attributes.name }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                            <Button className="--secondary --outline --mini --big-text"
                                    :onclick="()=>toggleUpdate(props.row)" v-if="$store.getters.hasAnyPermission(['update users'])">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        <Button
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)" v-if="$store.getters.hasAnyPermission(['destroy users'])">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import UsersCreateModal from "../../components/users/UsersCreateModal";
import UsersUpdateModal from "../../components/users/UsersUpdateModal";

export default {
    name: "users-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('users.name'),
                field: 'attributes.contact_person',
                sortable: false,
            },
            {
                label: this.$t('users.organisation'),
                field: 'attributes.organisation',
                sortable: false,
            },
            {
                label: this.$t('users.phone'),
                field: 'attributes.phone',
                sortable: false,
            },
            {
                label: this.$t('users.email'),
                field: 'attributes.email',
                sortable: false,
            },
            {
                label: this.$t('users.address'),
                field: 'attributes.address',
                sortable: false,
            },
            {
                label: this.$t('users.role'),
                field: this.roleFn,
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update users', 'destroy users']))
            columns.push({
                label: this.$t('users.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            users: [],
            is_loading_users: false,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        roleFn(row) {
            return row.relationships.role.data[0].attributes.name.charAt(0).toUpperCase() + row.relationships.role.data[0].attributes.name.slice(1);
        },
        toggleDelete(user) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('users.delete_user'),
                    message: this.$t('users.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`users/${user.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveUsers();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('users.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleCreate() {
            this.$modal.show(
                UsersCreateModal, {},
                {
                    name: 'users-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveUsers();
                    }
                }
            );
        },
        toggleUpdate(user) {
            this.$modal.show(
                UsersUpdateModal,
                {
                    user_id: user.id,
                },
                {
                    name: 'users-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveUsers();
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveUsers();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveUsers();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveUsers();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm, page: 1});
            else this.removeParam('term');

            this.retrieveUsers();
        },
        retrieveUsers() {
            this.is_loading_users = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('users', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.users = data.data;
                    this.totalRecords = data.meta.total;
                    this.$refs.table.changePage(data.meta.current_page);
                    this.is_loading_users = false;
                })
                .catch(e => {
                    this.is_loading_users = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrieveUsers();
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.users')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
